/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 *
 * based on https://codesandbox.io/s/react-calendar-heatmap-basic-demo
 */

.react-calendar-heatmap text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    fill: #c6c9cc;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5px;
    fill: #c6c9cc;
}

.react-calendar-heatmap rect:hover {
    font-family: Arial, Helvetica, sans-serif;
    stroke: #27282c;
    font-size: 5px;
    stroke-width: 1px;
}

/*
 * FetchyFox color scale
 *
 */
.react-calendar-heatmap .color-fetchyfox-0 {
    fill: #eeeeee;
}
.react-calendar-heatmap .color-fetchyfox-1 {
    fill: #FFF1E7;
}
.react-calendar-heatmap .color-fetchyfox-2 {
    fill: #FFE3D0;
}
.react-calendar-heatmap .color-fetchyfox-3 {
    fill: #FFD5B9;
}
.react-calendar-heatmap .color-fetchyfox-4 {
    fill: #FFC7A1;
}
.react-calendar-heatmap .color-fetchyfox-5 {
    fill: #FFBA8A;
}
.react-calendar-heatmap .color-fetchyfox-6 {
    fill: #FFAC73;
}
.react-calendar-heatmap .color-fetchyfox-7 {
    fill: #FF9E5B;
}
.react-calendar-heatmap .color-fetchyfox-8 {
    fill: #FF9044;
}
.react-calendar-heatmap .color-fetchyfox-max {
    fill: #FF832D;
}

/*
 * Github color scale (example)
 */

.react-calendar-heatmap .color-github-0 {
    fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
    fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
    fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
    fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
    fill: #1e6823;
}

